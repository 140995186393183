import firebase from 'firebase';
import 'firebase/messaging';


if (firebase.messaging.isSupported()) {
      var firebaseConfig = {
        apiKey: "AIzaSyAEZnjXPHTijCvKHEMeQ3z2DSb0ZSgonpQ",
        authDomain: "evntable.firebaseapp.com",
        projectId: "evntable",
        storageBucket: "evntable.appspot.com",
        messagingSenderId: "874644989256",
        appId: "1:874644989256:web:83eb1e11187ebcaf7cff7d",
        measurementId: "G-09NS2CY5PB"
        };
        // Initialize Firebase
        firebase.initializeApp(firebaseConfig);
    } else {
      console.log("browser doesn't support notifications");
        // let the user know their browser doesn't support notifications, probably Safari, eww
    }
// import firebase from 'firebase';

// var firebaseConfig = {
//   apiKey: "AIzaSyAEZnjXPHTijCvKHEMeQ3z2DSb0ZSgonpQ",
//   authDomain: "evntable.firebaseapp.com",
//   projectId: "evntable",
//   storageBucket: "evntable.appspot.com",
//   messagingSenderId: "874644989256",
//   appId: "1:874644989256:web:83eb1e11187ebcaf7cff7d",
//   measurementId: "G-09NS2CY5PB"
//   };
//   // Initialize Firebase
  
  // firebase.initializeApp(firebaseConfig);


export default firebase;
