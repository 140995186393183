// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
//import firebase from 'firebase'
import firebase from './firebase';
import { isSupported, getMessaging, getToken } from 'firebase/messaging'
import { connect } from 'react-firebase'
import './App.css'
import WebRoutes from './WebRoutes';
require('firebase/messaging');

// const firebaseAPI = firebase.initializeApp({
//   apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
//   authDomain: "rnmasterapp-c11e9.firebaseapp.com",
//   databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
//   projectId: "rnmasterapp-c11e9",
//   storageBucket: "rnmasterapp-c11e9.appspot.com",
//   messagingSenderId: "649592030497",
//   appId: "1:649592030497:web:7728bee3f2baef208daa60",
//   measurementId: "G-FYBCF3Z2W3"
// });

class App extends Component {
  componentDidMount() {
    if (firebase.messaging.isSupported()) {
    const msg=firebase.messaging();
    msg.requestPermission().then(()=>{
      return msg.getToken();
    }).then((data)=>{
      localStorage.setItem("fcm_token",data)
      console.warn("token",data)
    })
  }
  }
  render() {

    // const defaultAnalytics = firebaseAPI.analytics();
    // defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: "100%" }}>
        <WebRoutes />
      </View>
    );
  }
}

export default App;
  