//@ts-nocheck
import React, { lazy, Suspense } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter
} from 'react-router-dom';

/* Routes List start */

//Rahul dev

{/*Log Part*/ }
const Login = lazy(() => import('../../blocks/email-account-login/src/EmailAccount.web'));
const Signup = lazy(() => import('../../blocks/email-account-registration/src/EmailAccountRegistration.web'))
const AccessCode = lazy(() => import('../../blocks/email-account-registration/src/AccessCode.web'))
const SplashScree = lazy(() => import('../../blocks/email-account-registration/src/SplashScree.web'))
const ForgotPassword = lazy(() => import('../../blocks/forgot-password/src/ForgotPassword.web'))
const ChangePassword = lazy(() => import('../../blocks/forgot-password/src/ChangePassword.web'))
const SendEmail = lazy(() => import('../../blocks/forgot-password/src/SendEmail.web'))

{/*Survey*/ }
const Surveys = lazy(() => import('../../blocks/Surveys/src/Surveys.web'))
const SurveyList = lazy(() => import('../../blocks/Surveys/src/SurveyList.web'))
const SurveysCreate = lazy(() => import('../../blocks/Surveys/src/SurveysCreate.web'))
const SurveyEventFeedback = lazy(() => import('../../blocks/Surveys/src/SurveyEventFeedback.web'))
const SurveyProgramFeedback = lazy(() => import('../../blocks/Surveys/src/SurveyProgramFeedback.web'))
const SurveysReport = lazy(() => import('../../blocks/Surveys/src/SurveysReport.web'))
const SurveyNoList = lazy(() => import('../../blocks/Surveys/src/SurveyNoList.web'))

{/*PE Program*/ }
const PeProgramFeedback = lazy(() => import('../../blocks/events/src/PeProgramFeedback.web'))
const PeEventFeedback = lazy(() => import('../../blocks/events/src/EventFeedback.web'))
const EventFeedbackList = lazy(() => import('../../blocks/events/src/EventFeedbackList.web'))

{/*WE Document*/ }
const WoDocument = lazy(() => import('../../blocks/dashboard/src/Documents/Document.web'))
const DefineDocument = lazy(() => import('../../blocks/dashboard/src/Documents/DefineDocument.web'))
const DocumentSummary = lazy(() => import('../../blocks/dashboard/src/Documents/DocumentSummary.web'))
const EditEvent = lazy(() => import('../../blocks/events/src/PoEventEdit.web'))
const PeDocuments = lazy(() => import('../../blocks/dashboard/src/Documents/PeDocuments.web'));

{/*Qize*/ }
const QuizList = lazy(() => import('../../blocks/QuestionBank/src/Quiz.web'))
const QuizAdd = lazy(() => import('../../blocks/QuestionBank/src/QuizAdd.web'))
const QuizPreview = lazy(() => import('../../blocks/QuestionBank/src/QuizPreview.web'))
const QuizPreviewEdit = lazy(() => import('../../blocks/QuestionBank/src/QuizPreviewEdit.web'))
const QuizOptionPreview = lazy(() => import('../../blocks/QuestionBank/src/QuizOptionPreview.web'))
const QuizEditForm = lazy(() => import('../../blocks/QuestionBank/src/QuizEditForm.web'))
const QuizSubmit = lazy(() => import('../../blocks/QuestionBank/src/QuizSubmit.web'))
const QuizLeaderboard = lazy(() => import('../../blocks/QuestionBank/src/QuizLeaderBoard.web'))
const QuizPOLeaderBoard = lazy(() => import('../../blocks/QuestionBank/src/QuizPOLeaderBoard.web'))
const QuizReport = lazy(() => import('../../blocks/QuestionBank/src/QuizReport.web'))
const QuizAnalytic = lazy(() => import('../../blocks/QuestionBank/src/QuizAnalytic.web'))
//Rahul dev

//Rohit dev

{/*Event*/ }
const AddEvents = lazy(() => import('../../blocks/events/src/AddEvents.web'))
const Events = lazy(() => import('../../blocks/events/src/Events.web'))
const AddProgram = lazy(() => import('../../blocks/events/src/AddProgram.web'))
const Addvenue = lazy(() => import('../../blocks/events/src/AddVenue.web'))
const LoginList = lazy(() => import('../../blocks/events/src/LoginLst.web'))
const Addlogin = lazy(() => import('../../blocks/events/src/LoginList.web'))
const Codegenerate = lazy(() => import('../../blocks/events/src/EventAccessCodeGenerated.web'))
const WoDashBoard = lazy(() => import('../../blocks/events/src/WoDashBoard.web'))
const PostDetails = lazy(() => import('../../blocks/postcreation/src/PostDetails.web'))
const AddNewProgram = lazy(() => import('../../blocks/events/src/AddNewProgram.web'))

{/*post*/ }
const WoPost = lazy(() => import('../../blocks/postcreation/src/PostAdd.web'))
const PEPost = lazy(() => import('../../blocks/postcreation/src/PEPostAdd.web'))
const SurveysAnalysis = lazy(() => import('../../blocks/Surveys/src/SurveyAnalysis.web'))
const SurveysAnalysisPro = lazy(() => import('../../blocks/Surveys/src/SurveyAnalysisProgram.web'))
const SurveysReportProgram = lazy(() => import('../../blocks/Surveys/src/SurveyReportProgram.web'))
const EventAnalytics = lazy(() => import('../../blocks/analytics/src/Analyticss.web'))
const EventAnalyticsReport = lazy(() => import('../../blocks/analytics/src/EventAnalyticsReport.web'))
const EventAttendees = lazy(() => import('../../blocks/analytics/src/EventAttendees.web'))

//Rohit dev

//Prudhviraj
{/*Event*/ }
const PoEventDetailsScreen = lazy(() => import('../../blocks/events/src/PoEventDetailsScreen.web'))

//Prudhviraj

// Parth Panchal
{/*Chat*/ }
const PeNewChat = lazy(() => import('../../blocks/LiveChat2/src/PeNewChat.web'))
const PeGroupChat = lazy(() => import('../../blocks/LiveChat2/src/PeGroupChat.web'))

{/*Vanue*/ }
const Venues = lazy(() => import('../../blocks/location/src/PoVenue.web'))
const Venuemap = lazy(() => import('../../blocks/location/src/PoVenuemap.web'))
const ReachedPeopleList = lazy(() => import('../../blocks/location/src/PoReachedVenueList.web'))
const PeLocation = lazy(() => import('../../blocks/location/src/PeLocation.web'))
const PeUserStatus = lazy(() => import('../../blocks/location/src/PeUserStatus.web'))

{/*PoProgram*/ }
const PoProgram = lazy(() => import('../../blocks/events/src/PoProgram.web'))
const PoProgramDetails = lazy(() => import('../../blocks/events/src/PoProgramDetails.web'))
const PoEditProgram = lazy(() => import('../../blocks/events/src/PoEditProgram.web'))
const PoAttendees = lazy(() => import('../../blocks/events/src/PoAttendees.web'))
const PoLoginListDetails = lazy(() => import('../../blocks/events/src/PoLoginListDetails.web'))
const PEDashbaord = lazy(() => import('../../blocks/dashboard/src/Dashboard.web'))
const PEeventSchedule = lazy(() => import('../../blocks/events/src/PeEventSchedule.web'))
const PEprogram = lazy(() => import('../../blocks/events/src/PeProgram.web'))
// Parth Panchal

const POplans = lazy(() => import('../../blocks/FreeTrial/src/FreeTrial.web'))
const POplansDetails = lazy(() => import('../../blocks/FreeTrial/src/PlanDetails.web'))
const POaddLogin = lazy(() => import('../../blocks/FreeTrial/src/LoginAdded.web'))
const POpayment = lazy(() => import('../../blocks/FreeTrial/src/LoginCart.web'))
const POcardpayment = lazy(() => import('../../blocks/FreeTrial/src/TranscationStatus.web'))
const POfailcardpayment = lazy(() => import('../../blocks/FreeTrial/src/FailStatus.web'))
const POloginaddcardpayment = lazy(() => import('../../blocks/FreeTrial/src/LoginAddedStatus.web'))
const LoginStatus = lazy(() => import('../../blocks/FreeTrial/src/LoginStatus.web'))

import HomeScreen from "../../components/src/HomeScreen";

if (!HomeScreen.instance) {
    const defaultProps = {
        navigation: null,
        id: "HomeScreen"
    };
    const homeScreen = new HomeScreen(defaultProps);
}

/* Routes List End */

/* Import components */
import Loader from "../../components/src/Loader.web"
import PrivateRoute from "./PrivateRoute.web"
/* Import components */

const WebRoutes = () => {
    return (
        <Router>
            <Suspense fallback={<Loader loading />}>
                <Switch>
                    {/* Rahul dev */}
                    {/*Log Part*/}
                    <Route path="/" exact render={props => <SplashScree {...props} />} />
                    <Route path="/Login" exact render={props => <Login {...props} />} />
                    <Route path="/signup" exact render={props => <Signup {...props} />} />
                    <Route path="/accesscode/:id?/:code?" exact render={props => <AccessCode {...props} />} />
                    <Route path="/forgotpassword" exact render={props => <ForgotPassword {...props} />} />
                    <Route path="/changepassword" exact render={props => <ChangePassword {...props} />} />
                    <Route path="/sendemail" exact render={props => <SendEmail {...props} />} />

                    {/*Survey*/}
                    <PrivateRoute path="/feedback" exact render={props => <Surveys {...props} />} />
                    <PrivateRoute path="/feedbacklist/:id" exact render={props => <SurveyList {...props} />} />
                    <PrivateRoute path="/feedbackcreateform/:id" exact render={props => <SurveysCreate {...props} />} />
                    <PrivateRoute path="/eventFeedback" exact render={props => <SurveyEventFeedback {...props} />} />
                    <PrivateRoute path="/programFeedback" exact render={props => <SurveyProgramFeedback {...props} />} />
                    <PrivateRoute path="/surveyreport/:id" exact render={props => <SurveysReport {...props} />} />
                    <PrivateRoute path="/surveynolist/:id" exact render={props => <SurveyNoList {...props} />} />

                    {/*Pe Dashboard*/}
                    <PrivateRoute path="/programfeedback/:id" exact render={props => <PeProgramFeedback {...props} />} />
                    <PrivateRoute path="/peeventfeedback" exact render={props => <PeEventFeedback {...props} />} />
                    <PrivateRoute path="/peeventfeedbacklist" exact render={props => <EventFeedbackList {...props} />} />

                    {/*WE Document*/}
                    <PrivateRoute path="/wodocuments" exact render={props => <WoDocument {...props} />} />
                    <PrivateRoute path="/definedocument/:id" exact render={props => <DefineDocument {...props} />} />
                    <PrivateRoute path="/documentsummary/:id" exact render={props => <DocumentSummary {...props} />} />
                    <PrivateRoute path="/editevent/:id" exact render={props => <EditEvent {...props} />} />
                    <PrivateRoute path="/pedocuments" exact render={props => <PeDocuments {...props} />} />

                    {/* Quiz*/}
                    <PrivateRoute path="/quizlist" exact render={props => <QuizList {...props} />} />
                    <PrivateRoute path="/quizadd/:id" exact render={props => <QuizAdd {...props} />} />
                    <PrivateRoute path="/quizpreview/:id" exact render={props => <QuizPreview {...props} />} />
                    <PrivateRoute path="/quizpreviewedit/:id" exact render={props => <QuizPreviewEdit {...props} />} />
                    <PrivateRoute path="/quizoptionpreview" exact render={props => <QuizOptionPreview {...props} />} />
                    <PrivateRoute path="/quizeditform" exact render={props => <QuizEditForm {...props} />} />
                    <PrivateRoute path="/quizsubmit" exact render={props => <QuizSubmit {...props} />} />
                    <PrivateRoute path="/quizleaderboard" exact render={props => <QuizLeaderboard {...props} />} />
                    <PrivateRoute path="/leaderboard/:id" exact render={props => <QuizPOLeaderBoard {...props} />} />
                    <PrivateRoute path="/quizreport/:id" exact render={props => <QuizReport {...props} />} />
                    <PrivateRoute path="/quizanalytic/:id" exact render={props => <QuizAnalytic {...props} />} />
                    {/* Rahul dev */}

                    {/* Rohit dev */}
                    {/*Events*/}
                    <PrivateRoute path="/createevent" exact render={props => <AddEvents {...props} />} />
                    <PrivateRoute path="/createevent/:id" exact render={props => <AddEvents {...props} />} />
                    <PrivateRoute path="/events" exact render={props => <Events {...props} />} />
                    <PrivateRoute path="/addprogram" exact render={props => <AddProgram {...props} />} title="Index Page" />
                    <PrivateRoute path="/addvenue" exact render={props => <Addvenue {...props} />} />
                    <PrivateRoute path="/loginlist" exact render={props => <LoginList {...props} />} />
                    <PrivateRoute path="/loginlst" exact render={props => <Addlogin {...props} />} />
                    <PrivateRoute path="/codegenerate" exact render={props => <Codegenerate {...props} />} />
                    <PrivateRoute path="/postdetails/:id?" exact render={props => <PostDetails {...props} />} />

                    {/*Posts*/}
                    <PrivateRoute path="/poposts" exact render={props => <WoPost {...props} />} />
                    <PrivateRoute path="/peposts/" exact render={props => <PEPost {...props} />} />
                    <PrivateRoute path="/surveyreportprogram/:id" exact render={props => <SurveysReportProgram {...props} />} />
                    <PrivateRoute path="/eventanalytics" exact render={props => <EventAnalytics {...props} />} />
                    <PrivateRoute path="/eventanalyticsreport/:id?" exact render={props => <EventAnalyticsReport  {...props} />} />
                    <PrivateRoute path="/eventanttendees/:id?" exact render={props => <EventAttendees  {...props} />} />

                    {/*survey */}
                    <PrivateRoute path="/surveyanalysis/:id?" exact render={props => <SurveysAnalysis {...props} />} />
                    <PrivateRoute path="/surveyanalysisprogram/:id?" exact render={props => <SurveysAnalysisPro {...props} />} />

                    {/* Rohit dev */}

                    {/* Prudhviraj */}
                    {/*Events*/}
                    <PrivateRoute path="/WoDashBoard" exact render={props => <WoDashBoard {...props} />} />
                    <PrivateRoute path="/EventDetailsScreen/:id?" exact render={props => <PoEventDetailsScreen {...props} />} />
                    {/* Prudhviraj */}

                    {/* Parth Panchal */}
                    {/*programs*/}
                    <PrivateRoute path="/poprograms/:id?" exact render={props => <PoProgram {...props} />} />
                    <PrivateRoute path="/poprogramdetails/:id?" exact render={props => <PoProgramDetails {...props} />} />
                    <PrivateRoute path="/editprogram/:id?" exact render={props => <PoEditProgram {...props} />} />
                    <PrivateRoute path="/editprogram" exact render={props => <PoEditProgram {...props} />} />
                    <PrivateRoute path="/poattendees" exact render={props => <PoAttendees {...props} />} />
                    <PrivateRoute path="/pologinlistdetails" exact render={props => <PoLoginListDetails {...props} />} />
                    <PrivateRoute path="/pedashboard" exact render={props => <PEDashbaord {...props} />} />
                    <PrivateRoute path="/peeventschedule" exact render={props => <PEeventSchedule {...props} />} />
                    <PrivateRoute path="/peprogram/:id?" exact render={props => <PEprogram {...props} />} />
                    <PrivateRoute path="/addnewprogram" exact render={props => <AddNewProgram {...props} />} />

                    {/* Vanue */}
                    <PrivateRoute path="/venue" exact render={props => <Venues {...props} />} />
                    <PrivateRoute path="/venuemap/:id?" exact render={props => <Venuemap {...props} />} />
                    <PrivateRoute path="/reachedpeoplelist/:id?" exact render={props => <ReachedPeopleList {...props} />} />
                    <PrivateRoute path="/pelocation" exact render={props => <PeLocation {...props} />} />
                    <PrivateRoute path="/peuserstatus" exact render={props => <PeUserStatus {...props} />} />
                    
                    {/* Chat */}
                    <PrivateRoute path="/newchat" exact render={props => <PeNewChat {...props} />} />
                    <PrivateRoute path="/groupchat" exact render={props => <PeGroupChat {...props} />} />

                    {/* Parth Panchal */}

                    {/* PO Plans */}
                    <PrivateRoute path="/poplan" exact render={props => <POplans {...props} />} />
                    <PrivateRoute path="/poplandetail/:planId?" exact render={props => <POplansDetails {...props} />} />
                    <PrivateRoute path="/PoaddLogin" exact render={props => <POaddLogin {...props} />} />
                    <PrivateRoute path="/Popayment" exact render={props => <POpayment {...props} />} />
                    <PrivateRoute path="/posuccess" exact render={props => <POcardpayment {...props} />} />
                    <PrivateRoute path="/pofailed" exact render={props => <POfailcardpayment {...props} />} />
                    <PrivateRoute path="/posuccesslogins" exact render={props => <POloginaddcardpayment {...props} />} />
                    <PrivateRoute path="/posuccesslogin" exact render={props => <LoginStatus {...props} />} />

                </Switch>
            </Suspense>
        </Router>
    )
}

//@ts-ignore
export default withRouter(WebRoutes);